import { useEffect, useMemo } from 'react';
import { Options, MapboxSearch } from '@mapbox/search-js-core';

const DEFAULTS = MapboxSearch.defaults;

/**
 * A React hook that returns a {@link MapboxSearch} instance.
 *
 * @param {Options} options
 * @param {string} options.accessToken
 * @see {@link MapboxSearch}
 */
export function useMapboxSearch(
  options: Partial<{ accessToken: string } & Options>
): MapboxSearch {
  const search = useMemo(() => {
    return new MapboxSearch();
  }, []);

  useEffect(() => {
    const { accessToken, ...restOptions } = options;
    search.accessToken = accessToken;
    search.defaults = {
      ...DEFAULTS,
      ...restOptions
    };
  }, [options]);

  return search;
}
