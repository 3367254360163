import { useEffect, useMemo } from 'react';
import { AutofillOptions, MapboxAutofill } from '@mapbox/search-js-core';

const DEFAULTS = MapboxAutofill.defaults;

/**
 * A React hook that returns a {@link MapboxAutofill} instance.
 *
 * @param {AutofillOptions} options
 * @param {string} options.accessToken
 * @see {@link MapboxAutofill}
 */
export function useMapboxAutofill(
  options: Partial<{ accessToken: string } & AutofillOptions>
): MapboxAutofill {
  const autofill = useMemo(() => {
    return new MapboxAutofill();
  }, []);

  useEffect(() => {
    const { accessToken, ...restOptions } = options;
    autofill.accessToken = accessToken;
    autofill.defaults = {
      ...DEFAULTS,
      ...restOptions
    };
  }, [options]);

  return autofill;
}
